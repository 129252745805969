import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { Position } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable, sortArrayPerPositions } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import FixedTableTheme from '../FixedTableTheme'
import FixedPositionItem from './Items/FixedPositionItem'

interface Props {
    positions: Position[]
    networkName: string
    networkId: number
    search: string
    updatePosition: (pos: Position[], updated: Position) => void
    updateFixedPosition: (pos: Position[], isFixed: any) => void
    deletePosition: (position: Position) => void
}

const BundleRadioTable: React.FC<Props> = (props) => {
    const tableRef = React.useRef<any>()
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const orderedPositions: Position[] = useMemo(() => {
        if (props.search.length > 2) {
            return sortArrayPerPositions(
                props.positions.filter((p) => p.name.toLowerCase().includes(props.search.toLowerCase()))
            )
        }
        return sortArrayPerPositions(props.positions)
    }, [props.positions, props.search])

    const columns: Column<Position>[] = useMemo(() => {
        return [
            {
                title: t('networks.position') as string,
                field: 'position',
                render: (position: Position) => (
                    <div style={{ display: 'flex', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ fontWeight: 'bold' }}>{position.bundleInfo.position}</p>
                    </div>
                    // <PositionItem
                    //     onlyRead
                    //     position={position.bundleInfo.position}
                    //     radio={position}
                    //     length={props.positions.length}
                    //     onPressButton={(newPos: number) => {
                    //         const newArr = sortArrayPerPositions(props.positions)
                    //         const newObject = {
                    //             ...position,
                    //             bundleInfo: {
                    //                 ...position.bundleInfo,
                    //                 position: newPos,
                    //             },
                    //         }
                    //         const indexFound = newArr.findIndex((p) => p.id === position.id)
                    //         if (indexFound !== -1) {
                    //             newArr.splice(indexFound, 1)
                    //             newArr.splice(newPos - 1, 0, newObject)
                    //             const newOrderedArray = newArr.map((p, i) => ({
                    //                 ...p,
                    //                 bundleInfo: {
                    //                     ...p.bundleInfo,
                    //                     position: i + 1,
                    //                 },
                    //             }))

                    //             props.updatePosition(newOrderedArray, {
                    //                 ...position,
                    //                 bundleInfo: {
                    //                     ...position.bundleInfo,
                    //                     position: newPos,
                    //                 },
                    //             })
                    //         }
                    //     }}
                    // />
                ),
                width: '10%',
                headerStyle: { width: 80 },
                cellStyle: {
                    width: 80,
                    maxWidth: 80,
                    cellWidth: '10%',
                },
            },
            {
                title: t('networks.fixed_position') as string,
                field: 'fixedPosition',
                render: (position: Position) => (
                    <FixedPositionItem
                        position={position.bundleInfo.fixedPosition}
                        radio={position}
                        length={props.positions.length}
                        onPressButton={(newValue) => {
                            const foundPos = props.positions.find((p) => p.id === position.id)
                            if (foundPos) {
                                const newArray: Position[] = props.positions.map((p) => {
                                    if (p.id === foundPos.id) {
                                        return {
                                            ...p,
                                            bundleInfo: {
                                                ...p.bundleInfo,
                                                fixedPosition: newValue,
                                            },
                                        }
                                    }
                                    return p
                                })

                                props.updatePosition(newArray, {
                                    ...foundPos,
                                    bundleInfo: {
                                        ...foundPos.bundleInfo,
                                        fixedPosition: newValue,
                                    },
                                })
                            }
                        }}
                        onResetPosition={() => {
                            const foundPos = props.positions.find((p) => p.id === position.id)
                            if (foundPos) {
                                const newArray: Position[] = props.positions.map((p) => {
                                    if (p.id === foundPos.id) {
                                        return {
                                            ...p,
                                            bundleInfo: {
                                                ...p.bundleInfo,
                                                fixedPosition: null,
                                            },
                                        }
                                    }
                                    return p
                                })

                                props.updatePosition(newArray, {
                                    ...foundPos,
                                    bundleInfo: {
                                        ...foundPos.bundleInfo,
                                        fixedPosition: null,
                                    },
                                })
                            }
                        }}
                    />
                    // <TextInput
                    //     value={position.bundleInfo.fixedPosition?.toString() || ''}
                    //     alignCenter
                    //     bordered
                    //     width={80}
                    //     onTextChange={(newValue) => {
                    //         const foundPos = props.positions.find((p) => p.id === position.id)
                    //         if (foundPos) {
                    //             const newArray: Position[] = props.positions.map((p) => {
                    //                 if (p.id === foundPos.id) {
                    //                     return {
                    //                         ...p,
                    //                         bundleInfo: {
                    //                             ...p.bundleInfo,
                    //                             fixedPosition: parseInt(newValue, 10) || 0,
                    //                         },
                    //                     }
                    //                 }
                    //                 return p
                    //             })

                    //             props.updatePosition(newArray, {
                    //                 ...foundPos,
                    //                 bundleInfo: {
                    //                     ...foundPos.bundleInfo,
                    //                     fixedPosition: parseInt(newValue, 10) || 0,
                    //                 },
                    //             })
                    //         }
                    //     }}
                    // />
                ),
            },
            {
                title: t('networks.radio') as string,
                field: 'radios',
                render: (position: Position) => (
                    <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img
                            src={position.logo ? (position.logo as string) : PLACEHOLDER}
                            alt={position.name}
                            style={{ width: 60, height: 60 }}
                            onError={(e: any) => {
                                e.currentTarget.src = PLACEHOLDER
                            }}
                        />
                        <p
                            onClick={() =>
                                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${position.id}`, {
                                    state: {
                                        name: position.name,
                                        idNetwork: position.idNetwork,
                                        parent: { name: props.networkName, id: props.networkId },
                                    },
                                })
                            }
                            style={{ fontWeight: 'bold', color: COLORS.purple, cursor: 'pointer' }}
                        >
                            {position.name}
                        </p>
                    </div>
                ),
                width: '75%',
                cellStyle: {
                    cellWidth: '75%',
                },
            },
            {
                title: t('networks.sponsor') as string,
                field: 'sponsored',
                render: (position: Position) => (
                    <CheckboxInput
                        values={[{ label: '', status: position.bundleInfo.sponsored }]}
                        onClick={() => {
                            const foundPos = props.positions.find((p) => p.id === position.id)
                            if (foundPos) {
                                const newArray: Position[] = props.positions.map((p) => {
                                    if (p.id === foundPos.id) {
                                        return {
                                            ...p,
                                            bundleInfo: {
                                                ...p.bundleInfo,
                                                sponsored: !p.bundleInfo.sponsored,
                                            },
                                        }
                                    }
                                    return p
                                })
                                props.updatePosition(newArray, {
                                    ...position,
                                    bundleInfo: {
                                        ...position.bundleInfo,
                                        sponsored: !position.bundleInfo.sponsored,
                                    },
                                })
                            }
                        }}
                    />
                ),
                width: '6%',
                headerStyle: { width: 100, maxWidth: 100 },
                cellStyle: {
                    width: 100,
                    maxWidth: 100,
                    cellWidth: '6%',
                },
            },
        ]
    }, [t, props.positions, props.search])

    const actions = useMemo(
        () => [
            (position: Position) => ({
                icon: 'do_disturb_on',
                iconProps: { color: 'error' },
                tooltip: t('networks.delete'),
                onClick: () => {
                    props.deletePosition(position)
                },
            }),
        ],
        [props.positions, t]
    )

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={orderedPositions}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                actions={actions}
                ref={tableRef}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                    tableLayout: 'auto',
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default BundleRadioTable
