// export const FmWorldBaseUrl = 'http://172.16.0.150:3302/'
export const sso22BaseUrl = 'https://api.peperoni.ai/login-dev/'

import { getRequestHeader } from '../utility/functions'
import CustomAxios from '../utility/CustomAxios'
import appConfiguration from '../appConfiguration'

export const FmWorldBaseUrl = appConfiguration.fmwBaseUrl

export const addRadioToFormatGroup = (formatGroupId: number, radioId: number) => {
    return `${FmWorldBaseUrl}format-groups/${formatGroupId}/radios/${radioId}`
}

export const getFormatGroupRadios = (groupId: number) => {
    return `${FmWorldBaseUrl}format-groups/${groupId}/radios`
}

export const getFormatGroupDetail = (id: number) => {
    return `${FmWorldBaseUrl}format-groups/${id}`
}

export const createFormatGroupRadio = (groupId: number) => {
    return `${FmWorldBaseUrl}format-groups/${groupId}/radios`
}

export const deleteFormatGroupRadio = (groupId: number, radioId: number) => {
    return `${FmWorldBaseUrl}format-groups/${groupId}/radios/${radioId}`
}

export const deleteFormatGroupUrl = (id: number) => {
    return `${FmWorldBaseUrl}format-groups/${id}`
}

export const getFormatGroups = () => {
    return `${FmWorldBaseUrl}format-groups`
}

export const logFmWorldUser = async (userAccessToken: string) => {
    return await CustomAxios.post(`${FmWorldBaseUrl}auth/login`, {}, getRequestHeader(userAccessToken)).then((res) => {
        return res.data
    })
}

export const getCurrentFMWorldUser = async (userAccessToken: string) => {
    return await CustomAxios.post(`${FmWorldBaseUrl}me`, {}, getRequestHeader(userAccessToken)).then((res) => {
        return res.data
    })
}

export const firebaseUserDataPut = (userId: number) => {
    return `${FmWorldBaseUrl}/users/${userId}`
}

export const getRadios = () => {
    return `${FmWorldBaseUrl}radios`
}
export const getRadioInteractiveMUXItem = (id: number) => {
    return `${FmWorldBaseUrl}interactive-mux/${id}`
}

export const deleteRadioInteractiveMUXItem = (id: number) => {
    return `${FmWorldBaseUrl}interactive-mux/${id}`
}

export const createRadioInteractiveMUXItems = () => {
    return `${FmWorldBaseUrl}create-interactive-mux`
}

export const editRadioInteractiveMUXItems = (id: number) => {
    return `${FmWorldBaseUrl}interactive-mux/${id}`
}

export const getRadioInteractiveMUXItems = () => {
    return `${FmWorldBaseUrl}interactive-mux`
}

export const patchInteractiveMUXConfiguration = (id: number) => {
    return `${FmWorldBaseUrl}interactive-mux/${id}`
}

export const getRadioDetail = (id: number) => {
    return `${FmWorldBaseUrl}radios/${id}`
}

export const getRadiosName = (name: string, limit: number) => {
    return `${FmWorldBaseUrl}radios?query=${name}&limit=${limit}`
}

export const getNetworks = () => {
    return `${FmWorldBaseUrl}networks`
}

export const getRadioNetworks = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/radios`
}

export const getApiKeysNetwork = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/api-keys`
}

export const getApiKey = (idNetwork: number, idKey: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/api-keys/${idKey}`
}

export const getNetworkDetail = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}`
}

export const getFmFrequencies = () => {
    return `${FmWorldBaseUrl}frequencies/fm`
}

export const getFmFrequencyDetail = (id: number) => {
    return `${FmWorldBaseUrl}frequencies/fm/${id}`
}

export const getMuxList = () => {
    return `${FmWorldBaseUrl}mux`
}

export const getMuxDetail = (idMux: number) => {
    return `${FmWorldBaseUrl}mux/${idMux}`
}

export const getDabFrequencies = (idMux: number) => {
    return `${FmWorldBaseUrl}mux/${idMux}/frequencies`
}

export const getDabRadios = (idMux: number) => {
    return `${FmWorldBaseUrl}mux/${idMux}/radios`
}

export const getDabCityAssoc = (idMux: number, idCity: number) => {
    return `${FmWorldBaseUrl}mux/${idMux}/cities/${idCity}`
}

export const getDabRadioAssoc = (idMux: number, idRadio: number) => {
    return `${FmWorldBaseUrl}mux/${idMux}/radios/${idRadio}`
}

export const getRadioTypes = () => {
    return `${FmWorldBaseUrl}radio-types`
}

export const getCountries = () => {
    return `${FmWorldBaseUrl}countries`
}

export const getCategories = () => {
    return `${FmWorldBaseUrl}categories`
}

export const getRadioTags = () => {
    return `${FmWorldBaseUrl}tags`
}

export const getStreamDetail = (id: number) => {
    return `${FmWorldBaseUrl}streams/${id}`
}

export const getStreams = () => {
    return `${FmWorldBaseUrl}streams`
}

export const getStreamsOffline = () => {
    return `${FmWorldBaseUrl}streams_offline`
}

export const getShows = () => {
    return `${FmWorldBaseUrl}shows`
}

export const getShowDetail = (id: number) => {
    return `${FmWorldBaseUrl}shows/${id}`
}

export const getShowsRadio = (idRadio: number) => {
    return `${FmWorldBaseUrl}radios/${idRadio}/shows`
}

export const associateRadioStream = (radioId: number, streamId: number) => {
    return `${FmWorldBaseUrl}radios/${radioId}/streams/${streamId}`
}

export const getStates = () => {
    return `${FmWorldBaseUrl}states`
}

export const getProvinces = () => {
    return `${FmWorldBaseUrl}provinces`
}

export const getCities = () => {
    return `${FmWorldBaseUrl}cities`
}

export const getSchedule = (id: number) => {
    return `${FmWorldBaseUrl}radios/${id}/schedule`
}

export const getScheduleDetail = (id: number) => {
    return `${FmWorldBaseUrl}schedules/${id}`
}

export const createSchedule = () => {
    return `${FmWorldBaseUrl}schedules/`
}

export const associateShowRadio = (idRadio: number, idShow: number) => {
    return `${FmWorldBaseUrl}radios/${idRadio}/shows/${idShow}`
}

export const getXperiData = (idRadio: number) => {
    return `${FmWorldBaseUrl}xperi/${idRadio}`
}

export const createXperiData = () => {
    return `${FmWorldBaseUrl}xperi`
}

export const refreshToken = () => {
    return `${FmWorldBaseUrl}oauth/token`
}

export const getPendingElements = () => {
    return `${FmWorldBaseUrl}new-radio-requests`
}

export const editPendingElement = (elementId: number) => {
    return `${FmWorldBaseUrl}new-radio-requests/${elementId}`
}

export const getNetworksBundle = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/bundles`
}

export const duplicateBundle = (idBundle: number) => {
    return `${FmWorldBaseUrl}bundles/copy/${idBundle}`
}

export const getNetworksUsers = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/users`
}

export const getUsers = () => {
    return `${FmWorldBaseUrl}users`
}

export const createUsers = () => {
    return `${FmWorldBaseUrl}users`
}

export const getUsersDetail = (id: number) => {
    return `${FmWorldBaseUrl}users/${id}`
}

export const getNetworksPlayers = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/players`
}

export const createPlayer = () => {
    return `${FmWorldBaseUrl}players`
}

export const getPlayerDetail = (idPlayer: number) => {
    return `${FmWorldBaseUrl}players/${idPlayer}`
}

export const getNetworksPlayerDetail = (idPlayer: number) => {
    return `${FmWorldBaseUrl}players/${idPlayer}`
}

export const getPlayerBundles = (idPlayer: number) => {
    return `${FmWorldBaseUrl}players/${idPlayer}/bundles`
}

export const setPlayerMultiAssociation = (idPlayer: number) => {
    return `${FmWorldBaseUrl}players/${idPlayer}/multi-association`
}

export const getNetworksUsersDetail = (idNetwork: number, idUser: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/users/${idUser}`
}

export const getBundleRadios = (idBundle: number) => {
    return `${FmWorldBaseUrl}bundles/${idBundle}/radios`
}

export const getBundleRadiosDetail = (idBundle: number, idRadio: number) => {
    return `${FmWorldBaseUrl}bundles/${idBundle}/radios/${idRadio}`
}

export const getBundles = () => {
    return `${FmWorldBaseUrl}bundles`
}

export const getBundlesDetail = (idBundle: number) => {
    return `${FmWorldBaseUrl}bundles/${idBundle}`
}

export const getAvailableRadios = (idBundle: number) => {
    return `${FmWorldBaseUrl}bundles/${idBundle}/available_radios`
}

export const getRoles = () => {
    return `${FmWorldBaseUrl}roles`
}

export const getNetworkUserPermissions = (idNetwork: number | null) => {
    return `${FmWorldBaseUrl}grants/networks/${idNetwork}`
}

export const getMuxUserPermissions = (idMux: number | null) => {
    return `${FmWorldBaseUrl}grants/mux/${idMux}`
}

export const getUserData = (idUser: number) => {
    return `${FmWorldBaseUrl}users/${idUser}`
}

export const getPendingNetworkInvitations = () => {
    return `${FmWorldBaseUrl}pending-network-invitations`
}

export const getPendinMuxInvitations = () => {
    return `${FmWorldBaseUrl}pending-mux-invitations`
}

export const confirmNetworkInvite = (idNetwork: number) => {
    return `${FmWorldBaseUrl}networks/${idNetwork}/confirm-invitation`
}

export const getParser = () => {
    return `${FmWorldBaseUrl}system/available_parsers`
}

export const clearRedisCache = () => {
    return `${FmWorldBaseUrl}system/admin/clear-redis-cache`
}

export const syncBundlePositions = () => {
    return `${FmWorldBaseUrl}system/admin/sync-bundle-positions`
}

export const getMuxUsers = (muxId: number) => {
    return `${FmWorldBaseUrl}mux/${muxId}/users`
}

export const getMuxUsersDetail = (muxId: number, idUser: number) => {
    return `${FmWorldBaseUrl}mux/${muxId}/users/${idUser}`
}

export const confirmMuxInvite = (muxId: number) => {
    return `${FmWorldBaseUrl}mux/${muxId}/confirm-invitation`
}

export const getMuxServices = (muxId: number) => {
    return `${FmWorldBaseUrl}/mux/${muxId}/services`
}

export const getRadioTxControlMuxConfigurations = (radioId: number) => {
    return `${FmWorldBaseUrl}/radios/${radioId}/tx-control-mux-configurations`
}

export const editMuxService = (muxId: number, serviceId: number) => {
    return `${FmWorldBaseUrl}/mux/${muxId}/services/${serviceId}`
}

export const getPortalSession = (networkId: number) => {
    return `${FmWorldBaseUrl}/networks/${networkId}/portal-session`
}
