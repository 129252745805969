import React, { useEffect, useState } from 'react'
import { Position } from '../../../types/data'
import Button from '../../Button/Button'
import TextInput from '../../TextInput/TextInput'

interface Props {
    position: number | null
    radio: Position
    length: number
    onPressButton: (newPosition: number) => void
    onResetPosition: () => void
    onlyRead?: boolean
}

const FixedPositionItem: React.FC<Props> = (props) => {
    const [currentPosition, setCurrentPosition] = useState<number | null>(props.position)

    useEffect(() => {
        setCurrentPosition(props.position)
    }, [props.position, props.radio])

    return (
        <div style={{ display: 'flex', gap: 10 }}>
            <TextInput
                value={currentPosition ? currentPosition.toString() : ''}
                alignCenter
                bordered
                onTextChange={(e) => {
                    if (e === '') {
                        setCurrentPosition(null)
                        return
                    }

                    if (e === '0') {
                        setCurrentPosition(1)
                        return
                    }

                    if (parseInt(e, 10) > props.length) {
                        setCurrentPosition(props.length)
                        return
                    }

                    setCurrentPosition(parseInt(e, 10))
                }}
                width={80}
                {...(props.onlyRead ? { disabled: true } : {})}
            />
            {currentPosition && props.position !== currentPosition ? (
                <Button
                    label="✓"
                    onPress={() => {
                        props.onPressButton(currentPosition)
                    }}
                    type="submit"
                    width={50}
                />
            ) : null}
            {currentPosition && props.position === currentPosition ? (
                <Button
                    label="X"
                    onPress={() => {
                        props.onResetPosition()
                    }}
                    type="submit"
                    width={50}
                />
            ) : null}
        </div>
    )
}

export default FixedPositionItem
