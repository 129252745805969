/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BundlePositionList from '../../../components/BundlePositionList/BundlePositionList'
import Button from '../../../components/Button/Button'
import Icon from '../../../components/Icon'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import BundleModal from '../../../components/Modals/BundleModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import TextInput from '../../../components/TextInput/TextInput'
import { duplicateBundle, getBundles, getBundlesDetail, getNetworksBundle } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { PLACEHOLDER } from '../../../resources/constants'
import { Bundle, Network } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import { Grants } from '../../../utility/Grants'
import { VoidBundle, voidBundle } from '../../../utility/voidConstants'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../../types/reducers'

interface Props {
    permissions: Grants[]
}

const BundlesTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [bundles, setBundles] = useState<Bundle[]>([])
    const [selectBundleForActions, setSelectBundleForActions] = useState<Bundle | null>(null)
    const [selectBundleToEdit, setSelectBundleToEdit] = useState<Bundle | VoidBundle | null>(null)
    const [selectBundleToDelete, setSelectBundleToDelete] = useState<Bundle | null>(null)
    const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null)
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    const { t } = useTranslation()

    const fetchBundles = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                return
            }
            const bundlesFetch = await FmWorldAxios.get(getNetworksBundle(selectedNetwork.id)).then((res) => {
                return res.data
            })
            setBundles(bundlesFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const updateBundles = async () => {
        await fetchBundles()
    }

    const actionsModal = [
        {
            text: t('networks.edit_bundle'),
            color: COLORS.purple,
            onClick: () => {
                setSelectBundleForActions(null)
                setSelectBundleToEdit(selectBundleForActions)
            },
        },
        {
            text: t('networks.duplicate_bundle'),
            color: COLORS.darkGrey,
            onClick: () => {
                if (selectBundleForActions) {
                    setSelectBundleForActions(null)
                    void handleDuplicateBundle(selectBundleForActions)
                }
            },
        },
        {
            text: t('networks.delete_bundle'),
            color: COLORS.redDelete,
            onClick: () => {
                setSelectBundleForActions(null)
                setSelectBundleToDelete(selectBundleForActions)
            },
        },
    ]

    const handleEditBundle = async (bundle: Bundle) => {
        if (selectBundleToEdit as Bundle) {
            setError('')
            setIsUpdating(true)
            try {
                const update = {
                    name: bundle.name,
                    description: bundle.description,
                    logo: bundle.logo,
                    isActive: bundle.isActive,
                    isGroup: bundle.isGroup,
                    isReorderEnabled: bundle.isReorderEnabled,
                    type: bundle.type,
                }
                const formData = new FormData()
                Object.keys(update).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = update[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.put(getBundlesDetail((selectBundleToEdit as Bundle).id), formData)
                setSelectBundleToEdit(null)
                await updateBundles()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleDuplicateBundle = async (bundle: Bundle) => {
        if (bundle) {
            setError('')
            setIsCreating(true)
            try {
                if (!selectedNetwork) {
                    return
                }
                const duplicated = {
                    name: `${bundle.name} copy`,
                    idNetwork: selectedNetwork.id,
                    description: bundle.description,
                    logo: bundle.logo,
                    isActive: bundle.isActive,
                    isGroup: bundle.isGroup,
                    isReorderEnabled: bundle.isReorderEnabled,
                    type: bundle.type,
                }
                await FmWorldAxios.post(duplicateBundle(bundle.id), duplicated)
                await updateBundles()
            } catch (error: any) {
                setError(error.toString())
            }
            setIsCreating(false)
        }
    }

    const handleCreateBundle = async (bundle: VoidBundle) => {
        if (selectBundleToEdit as VoidBundle) {
            setError('')
            setIsCreating(true)
            try {
                if (!selectedNetwork) {
                    return
                }
                const update = {
                    name: bundle.name,
                    description: bundle.description,
                    logo: bundle.logo,
                    isActive: bundle.isActive,
                    isGroup: bundle.isGroup,
                    idNetwork: selectedNetwork.id,
                    isReorderEnabled: bundle.isReorderEnabled,
                    type: bundle.type,
                }
                const formData = new FormData()
                Object.keys(update).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = update[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.post(getBundles(), formData)
                setSelectBundleToEdit(null)
                await updateBundles()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsCreating(false)
        }
    }

    const handleDeleteBundle = async () => {
        if (selectBundleToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getBundlesDetail(selectBundleToDelete.id))
                setSelectBundleToDelete(null)
                await updateBundles()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }
    useEffect(() => {
        void fetchBundles()
    }, [selectedNetwork])

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.bundles')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_your_bundles')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
            </div>
            <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 2 }}>
                    <TextInput
                        value={search}
                        placeholder={t('general.search')}
                        icon="search"
                        iconPosition="right"
                        onTextChange={(v) => {
                            setSearch(v)
                        }}
                    />
                </div>

                <div style={{ flex: 1 }}>
                    <Button
                        label={t('general.refresh')}
                        onPress={() => {
                            setSearch('')
                            void updateBundles()
                        }}
                        type="submit"
                        background="rgba(100,38,119,0.14)"
                        border={`2px solid ${COLORS.purple}`}
                        color={COLORS.purple}
                    />
                </div>
                {props.permissions.includes(Grants.CREATE_BUNDLE) ? (
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.add')}
                            onPress={() => {
                                setSelectBundleToEdit(voidBundle)
                            }}
                            type="submit"
                        />
                    </div>
                ) : null}
            </div>
            {bundles.length === 0 ? (
                <div className="loadingGrid">
                    {props.permissions.includes(Grants.CREATE_BUNDLE) ? (
                        <p className="title">
                            {t('general.press_on')}{' '}
                            <span style={{ color: COLORS.purple, fontWeight: 700 }}>{t('general.add')}</span>{' '}
                            {t('networks.to_add_bundle')}
                        </p>
                    ) : (
                        <p className="title">{t('networks.contact_admin_bundle')}</p>
                    )}
                </div>
            ) : null}
            <div className="bundlesMenu">
                {bundles
                    .filter((b) => b.name.toLowerCase().includes(search.toLowerCase()))
                    .map((b) => {
                        return (
                            <div
                                onClick={() => {
                                    if (selectedBundle && selectedBundle.id === b.id) {
                                        return
                                    }
                                    setSelectedBundle(b)
                                }}
                                className={
                                    selectedBundle && selectedBundle.id === b.id
                                        ? 'bundlesMenu_item-selected'
                                        : 'bundlesMenu_item'
                                }
                                key={b.id.toString()}
                            >
                                <img
                                    className="bundlesMenu_item__logo"
                                    alt={`${b.name} logo`}
                                    src={b.logo ? b.logo : PLACEHOLDER}
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                <p className="bundlesMenu_item__name">{b.name}</p>
                                <div className="bundlesMenu_item__icon" onClick={() => setSelectBundleForActions(b)}>
                                    <Icon name="more" size={20} color="#A9ABAC" />
                                </div>
                            </div>
                        )
                    })}
            </div>
            {selectedBundle && selectedBundle.id && selectedNetwork ? (
                <div>
                    <BundlePositionList
                        bundle={selectedBundle}
                        networkName={selectedNetwork.name}
                        networkId={selectedNetwork.id}
                    />
                </div>
            ) : null}

            <ActionsModal
                isVisible={selectBundleForActions !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectBundleForActions(null)}
                actions={actionsModal}
            />
            <BundleModal
                isVisible={selectBundleToEdit !== null}
                onClose={() => setSelectBundleToEdit(null)}
                onSave={(b) => {
                    if (b && (b as Bundle).id) {
                        void handleEditBundle(b as Bundle)
                    } else {
                        void handleCreateBundle(b as VoidBundle)
                    }
                }}
                bundle={selectBundleToEdit}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteBundle()
                }}
                title={`${t('networks.delete_this_bundle')} ${selectBundleToDelete?.name}`}
                text={`${t('networks.want_delete_bundle')} ${selectBundleToDelete?.name}?`}
                onClose={() => {
                    setSelectBundleToDelete(null)
                }}
                isVisible={selectBundleToDelete !== null}
            />
            <LoadingModal isVisible={isUpdating || isCreating} text={t('loadings.updating_bundle')} />
        </div>
    )
}

export default BundlesTab
